import { HomeWorkStatus } from '@constants/homeWorks';
import { CardTypes, ProgressStatusList } from '@features/homeworks/constants';
import { AnswerStatus } from '@constants/answer';
import { ButtonVariant } from './button';
import { BarChartType } from './chart';

export type LabelVarient = 'yet_to_start' | 'in_progress' | 'fininshed';
export type LabelSize = 'small' | 'large';

export interface HomeworkLabelProps {
  size?: LabelSize;
  label: LabelVarient;
  className?: string;
}

export enum QuestionType {
  LONG_ANSWER = 'Long Answer',
  SHORT_ANSWER = 'Short Answer',
  MCQ = 'MCQ',
  MSQ = 'MCQ Multi Select',
  FILL_IN_THE_BLANKS = 'Fill In The Blanks',
  AUDIO_INPUT = 'Audio'
}

export interface AnswerAttachment {
  createdAt: string | null;
  updatedAt: string | null;
  id: string;
  answerId: string;
  attachmentId: string;
  type: string;
  notes: string | null;
  attachments: Attachment;
}

export interface Answer {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  id: string;
  questionId: string;
  answer: string | null;
  isCorrect: boolean;
  createdBy: string;
  updatedBy: string;
  answerAttachments: AnswerAttachment[];
}

export interface Attachment {
  name: string;
  extension: string;
  path: string;
  url: string;
}

export enum AttachmentType {
  IMAGE = 'Illustration',
  VIDEO = 'Video',
  AUDIO = 'Audio',
  DOCUMENT = 'Document'
}
export interface QuestionAttachment {
  createdAt: string | null;
  updatedAt: string | null;
  id: string;
  questionId: string;
  attachmentId: string;
  type: AttachmentType;
  notes: string | null;
  attachments: Attachment;
}
export type ProgressStatusType = ProgressStatusList;

export enum ResultCardType {
  CORRECT = 'correct',
  WRONG = 'wrong',
  PARTIAL = 'partial'
}

export interface ResultCardProps {
  type: AnswerStatus;
  points: {
    maximumPoints: string;
    points: string;
  };
}

export interface ResultProps {
  onButtonClick: () => void;
  buttonVariant?: ButtonVariant;
  buttonLabel?: string;
  question?: QuestionSummary;
  assignmentId?: string;
  isLastQuestion?: boolean;
  studentId?: string;
}

export interface SummaryProps {
  onContinue: () => void;
  onClose: () => void;
  homeWorkId: string;
}

export interface UnsavedChangesProps {
  buttonStyle: string;
  onSkip: () => void;
  onClose: () => void;
}

export interface HomeWork {
  attemptedQuestionsCount: number;
  fromDate: string;
  id: string;
  pointsScored?: number;
  status?: HomeWorkStatus;
  subjectId?: string;
  subjectName?: string;
  title?: string;
  toDate: string;
  totalPoints?: number;
  totalQuestionCount: number;
}

export interface HomeWorkDetailsModalProps {
  details: HomeWork;
  onStart: () => void;
  isMobile: boolean;
  studentId?: string;
  isStudent: boolean;
}

export interface HomeWorkDetails {
  title: string;
  subject: string;
  topics: string[];
  skills: string[];
  fromDate?: string;
  toDate?: string;
  totalMarks?: number;
  attemptedQuestionsCount: number;
  totalQuestionCount: number;
  submissionStatus: ProgressStatusType;
}

export type SubmitAnswerRequest = {
  param: {
    homeworkId: string;
    questionId: string;
  };
  body: {
    answer?: string;
    answers?: string[];
    worksheetImgKey?: string;
    responseDuration: number;
    audioUrlKey?: string;
  };
};

export enum SubmissionStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED'
}
export interface QuestionSummary {
  mark: number;
  maximumMark: number;
  questionId: string;
  result: AnswerStatus;
  type: string;
}

export interface QuestionDetails {
  homeworkAttemptDetails: any;
  questionDetails: any;
}

export interface TextFieldProps {
  data: string;
  isLoading: boolean;
  title: string;
  strength?: string;
  weakness?: string;
}

export interface PerfomanceMetricsProps {
  title: string;
  subTitle?: string;
  primaryValue: number[];
  secondaryValue?: number[];
  showLabel?: boolean;
  labels: string[];
  backgroundColor: string[];
  type?: BarChartType;
  isHorizontal?: boolean;
  chartClassName?: string;
  barThickness?: number;
  dataLabelFontType?: 'mobile' | 'desktop';
  isLoading?: boolean;
}

export enum GradingStatus {
  INCORRECT = 'INCORRECT',
  CORRECT = 'CORRECT',
  PARTIAL = 'PARTIALLY_CORRECT'
}

export interface QuestionMarks {
  questionId: string;
  marks: number;
  gradingStatus: GradingStatus;
}
export interface MarksChartProps {
  questionMarks: QuestionMarks[];
  isLoading?: boolean;
}

export interface MarkCardProps {
  questionNumber: number;
  status: GradingStatus;
}
export interface OverallPerfomanceProps {
  details: any;
  isLoading: boolean;
}

export interface StudentType {
  id: string;
  name: string;
  avatarUrl?: string;
  schoolId: string;
}

export interface StudentTabsProps {
  tabs: StudentType[];
  selectedTab?: StudentType;
  onSelectTab: (tab: StudentType) => void;
}

export interface StudentDropdownProps {
  tabs: StudentType[];
  selectedTab?: StudentType;
  onSelectTab: (tab: StudentType) => void;
}

export enum InfoCardVariant {
  BLUE,
  GREEN,
  YELLOW
}

export type SkillType = {
  skillId: string;
  skillName: string;
  successRate: string;
};

export interface StudentPerfomanceGraphProps {
  date: { startDate: Date; endDate: Date };
  studentId: string;
  className?: string;
  subjectId: string;
}

export interface LineGraphMetricsProps {
  title: string;
  subTitle?: string;
  primaryValue: number[];
  secondaryValue: number[];
  labels: string[];
  primaryColor: string;
  secondaryColor: string;
}

export interface InfoCardProps {
  label: string;
  value: string | number;
  iconName: string;
  variant: InfoCardVariant;
  className?: string;
  valueClassName?: string;
}

export interface HomeworkTableProps {
  date: { startDate: Date; endDate: Date };
  studentId: string;
  subjectId: string;
}

export interface StudentHomeworkSummaryProps {
  assignmentId: string;
  studentId: string;
  studentName?: string;
  isOpen: boolean;
  handleClose: () => void;
  summaryTitle?: string;
}

export interface LevelUpProps {
  isOpen: boolean;
  level: string;
  isLoading: boolean;
  handleClose: () => void;
  nextLevelImageUrl: string;
}

export interface HomeWorkCardProps {
  data: HomeWork;
  onCardClick?: () => void;
  onBackButtonClick?: () => void;
  type: CardTypes;
  isParent?: boolean;
  progressBarStyle?: 'default' | 'gradient' | 'patterned';
}
