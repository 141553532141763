/* eslint-disable import/prefer-default-export */
export const homeworkTabs = [
  {
    id: 1,
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    id: 2,
    label: 'Overdue',
    value: 'OVERDUE'
  },
  {
    id: 3,
    label: 'Completed',
    value: 'COMPLETED'
  }
];

export const cardThemes = ['blue', 'pink', 'yellow', 'green'];

export enum CardTypes {
  YET_TO_START = 'YET_TO_START',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DETAILS = 'DETAILS',
  OVERDUE = 'OVERDUE',
  LATE_SUBMISSION = 'LATE_SUBMISSION',
  YET_TO_START_OVERDUE = 'YET_TO_START_OVERDUE',
  IN_PROGRESS_OVERDUE = 'IN_PROGRESS_OVERDUE'
}

export enum ProgressStatusList {
  YET_TO_START = 'YET_TO_START',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CUSTOM = 'CUSTOM',
  REVIEW = 'REVIEW',
  OVERDUE = 'OVERDUE',
  LATE_SUBMISSION = 'LATE_SUBMISSION',
  YET_TO_START_OVERDUE = 'YET_TO_START_OVERDUE',
  IN_PROGRESS_OVERDUE = 'IN_PROGRESS_OVERDUE'
}

export enum CardThemes {
  BLUE = 'blue',
  PINK = 'pink',
  YELLOw = 'yellow',
  GREEN = 'green'
}

export enum FileUploadTypes {
  HOMEWORK_WORKSHEET = 'HOMEWORK_WORKSHEET',
  AUDIO_RESPONSE = 'AUDIO_RESPONSE'
}

export const HomeWorkFilterOptions = [
  { id: 1, label: 'In-progress', value: ProgressStatusList.IN_PROGRESS },
  { id: 2, label: 'Completed', value: ProgressStatusList.COMPLETED },
  { id: 3, label: 'Yet to start', value: ProgressStatusList.YET_TO_START }
];

export const summaryTableColumns = [
  { Header: 'Question Number', accessor: 'questionNumber', width: '20%' },
  { Header: 'Type', accessor: 'type', width: '30%' },
  { Header: 'Result', accessor: 'result', width: '30%' },
  { Header: 'Points', accessor: 'points' }
];

export const homeworkDetailsTabs = [
  {
    id: 1,
    label: 'Overall Perfomance'
  },
  {
    id: 2,
    label: 'Questions Overview'
  }
];

export const questionTypePerfomanceData = {
  title: 'Question Type Performance',
  subTitle: 'Success Rate (%) / Question Type',
  bgColor: ['#B9DDFE', '#FFDCAA', '#ECC5D3', '#CBBCFF', '#E6E8F0', '#FFDCAA'],
  labels: ['MCQ', 'SA', 'LA', 'FIB', 'MSQ', 'Audio']
};

export const commonErrortypesData = {
  title: 'Common error types',
  subTitle: 'Error Rate (%) / Error Type',
  bgColor: ['#CBBCFF'],
  labels: [
    'Visualization',
    'Calculations',
    'Graphical Analysis',
    'Problem Solving'
  ]
};

export const completionTimeData = {
  title: 'Completion Time',
  subTitle: 'Time (mins)'
};

export const markTypes = [
  {
    id: 1,
    label: 'No Mark',
    value: 'no_mark',
    color: 'bg-[#E6E8F0]'
  },
  {
    id: 2,
    label: 'Partial Mark',
    value: 'partial_mark',
    color: 'bg-[#FFDCAA]'
  },
  {
    id: 3,
    label: 'Full Mark',
    value: 'full_mark',
    color: 'bg-[#B2DDA3]'
  }
];

export const HomeworkCardButtonLabel = {
  REVIEW_HOMEWORK: 'Review Homework',
  VIEW_HOMEWORK: 'View Homework',
  RESUME_HOMEWORK: 'Resume Homework',
  START_HOMEWORK: 'Start Homework'
};

export const reportsTab = [
  {
    id: 1,
    label: 'Yearly'
  },
  {
    id: 2,
    label: '7 Days'
  },
  {
    id: 3,
    label: '30 Days'
  }
];

export const getDateRange = (label: string) => {
  const today = new Date();
  let startDate;
  let endDate;

  switch (label) {
    case 'Yearly':
      startDate = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
      endDate = new Date(today.getFullYear(), 11, 31); // December 31st of the current year
      break;
    case '7 Days':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 7);
      endDate = today;
      break;
    case '30 Days':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 30);
      endDate = today;
      break;
    default:
      startDate = today;
      endDate = today;
  }

  return { startDate, endDate };
};

export const skillAreaData = {
  title: 'Top 5 Strong Skill Areas',
  subTitle: 'Skill / Success Rate (%)',
  bgColor: ['#D3CAFF', '#B9DDFE', '#CBE8C1', '#FFE8C6', '#ECC5D3']
};

export const weakAreaData = {
  title: 'Top 5 Weak Skill Areas',
  subTitle: 'Skill / Success Rate (%)',
  bgColor: ['#ECC5D3']
};

export const completionRateData = {
  title: 'Completion Rate',
  subTitle: 'Total Assigned Homeworks / Status',
  bgColor: ['#B9DDFE', '#FFDCAA', '#ECC5D3'],
  labels: ['Completed', 'Partially completed', 'Not completed']
};

export const studentHomeworkTable = [
  { Header: 'HOMEWORK NAME', accessor: 'homeworkName', width: '20%' },
  { Header: 'SCORE', accessor: 'score', width: '20%', sort: true },
  { Header: 'AVG CLASS %', accessor: 'classPercentage', width: '20%' },
  { Header: 'COMPLETION TIME', accessor: 'completionTime', width: '20%' },
  { Header: 'ACTIONS', accessor: 'action', width: '20%' }
];

export const DEFAULT_STUDENT_PER_LIST = 8;

export const requiredReportKeys = [
  'totalMarks',
  'marksObtained',
  'questionTypePerformance',
  'questionMarks',
  'commonErrorTypes',
  'feedback',
  'questionCompletionTime'
];
